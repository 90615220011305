import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 110;
`;

export const Image = styled.div<{url: string}>`
  background-image: url("${props => props.url}");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const Label = styled.label`
  position: absolute;
  inset: auto 0 100px;
  text-align: center;
  display: block;
  width: fit-content;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: whitesmoke;
  font-weight: 600;
  padding: 8px;
  border-radius: 8px;
`;

export const Description = styled.label`
  display: block;
  font-size: 0.9em;
  font-weight: 400;
`;
