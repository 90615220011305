import {Language} from "./i18next";

export const storage = {

    saveLang(lang: Language) {
        localStorage.setItem("lang", lang)
    },

    getLang(): Language {
        return (localStorage.getItem("lang") as Language) ?? Language.EN;
    },

};
