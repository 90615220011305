import React, {ImgHTMLAttributes, useEffect, useState} from "react";
import styled from "styled-components";
import classNames from "classnames";


interface Props extends ImgHTMLAttributes<HTMLDivElement> {
    minifiedSrc?: string;
    nanoSrc?: string;
    src: string;
    blur?: boolean;
}

const DecoratedImage = styled.img`
  filter: blur(0px);
  //transition: all 0.5s ease-in-out;
  &.loading {
    filter: blur(10px);
    clip-path: inset(0);
  }
`;

export const ProgressiveImage: React.FC<Props> = (props) => {

    const {minifiedSrc, nanoSrc, src, blur, ...rest} = props;
    const [image, setImage] = useState(nanoSrc ?? minifiedSrc);
    const loading = image !== src;

    useEffect(() => {
        const loadFull = () => {
            const loadingStart = Date.now();
            const img = new Image();
            img.src = src;
            img.onload = () => setImage(src);
        };
        const loadMini = () => {
            const img = new Image();
            img.src = minifiedSrc!;
            img.onload = () => {
                setImage(minifiedSrc);
                loadFull();
            };
        };
        (nanoSrc && minifiedSrc && loadMini()) || loadFull();
    }, [src]);

    return <DecoratedImage
        src={image}
        {...rest}
        className={classNames(rest.className, {loading: image === nanoSrc || (blur && loading)})}
    />;

};
