import React, {useRef} from "react";
import * as S from "./styles";
import image from "./images/melting_muses.jpg";
import imageMini from "./images/melting_muses-mini.jpeg";
import imageNano from "./images/melting_muses-nano.jpeg";
import {FullscreenViewService} from "../../services/fullscreen-view-service";
import {ProgressiveImage} from "../ProgressiveImage/ProgressiveImage";
import {useTranslation} from "react-i18next";

export const MusesPage: React.FC = () => {

    const {t} = useTranslation();

    return <div style={{position: "static"}}>

        <div onClick={() => FullscreenViewService.instance.show(image, null, null)}>
            <ProgressiveImage src={image} minifiedSrc={imageMini} nanoSrc={imageNano} style={{width: "100%", position: "absolute", top: 0, zIndex: -10}}/>
            <img src={imageMini} style={{width: "100%", visibility: "hidden"}}/>
        </div>

        <S.Text style={{backgroundColor: "white"}}>
            <S.Title>{t("muses.title")}</S.Title>
            <p>{t("muses.paragraph1")}</p>
            <p>{t("muses.paragraph2")}</p>
            <p>{t("muses.paragraph3")}</p>
            <p>{t("muses.paragraph4")}</p>
            <p>{t("muses.paragraph5")}</p>
            <p>{t("muses.paragraph6")}</p>
            <p>{t("muses.paragraph7")}</p>
            <p>{t("muses.paragraph8")}</p>
            <p>{t("muses.paragraph9")}</p>
            <p>{t("muses.paragraph10")}</p>
        </S.Text>
    </div>;

};
