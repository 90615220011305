import React from "react";
import * as S from "./styles";
import image from "./images/about.jpg";
import imageMini from "./images/about-mini.jpeg";
import imageNano from "./images/about-nano.jpeg";
import {ProgressiveImage} from "../ProgressiveImage/ProgressiveImage";
import {useTranslation} from "react-i18next";

export const AboutPage: React.FC = () => {

    const {t} = useTranslation();

    return <div style={{position: "static"}}>

        <ProgressiveImage
            src={image}
            minifiedSrc={imageMini}
            nanoSrc={imageNano}
            style={{width: "100%", position: "absolute", top: 0, zIndex: -10}}
        />
        <img src={image} style={{width: "100vw", visibility: "hidden"}}/>

        <S.Text style={{backgroundColor: "white"}}>
            <S.Title>{t("about.title")}</S.Title>
            <p>{t("about.paragraph1")}</p>
            <p>{t("about.paragraph2")}</p>
        </S.Text>
    </div>;

};
