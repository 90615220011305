import styled from "styled-components";

export const Container = styled.div`
  inset: 0;
  margin: 40vh auto 40px;
  //height: fit-content;
  font-size: 4vw;
  width: 100%;
  text-align: center;
  a {
    color: black;
  }
`;
