import styled from "styled-components";

export const Button = styled.button`
  background-color: whitesmoke;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: none;
  line-height: 50px;
  padding: 0;
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 2em;
  cursor: pointer;
  transition: all ease-in-out 300ms;
  z-index: 100;
  &.hidden {
    display: none;
  }
  &:hover {
    background-color: #CCC;
  }
`;

export const ButtonLabel = styled.span`
  color: black;
  transform: translateY(-7px);
  display: block;
`;
