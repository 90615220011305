import styled from "styled-components";

export const RootContainer = styled.div<{height: number}>`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  height: ${props => props.height}px;
  width: 100vw;
  overflow: hidden;
  
  @media (max-width: 768px) {
    display: block;
    //height: initial;
    overflow-y: unset;
  }
`;

export const Main = styled.main`
  //position: relative;
  height: 100%;
  width: 100%;
  //overflow-y: auto;
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 1;
  @media (max-width: 768px) {
    overflow-y: unset;
    height: auto;
  }
`;