import styled from "styled-components";
import {Link as RRLink} from "react-router-dom";

const MENU_WIDTH = "240px";

export const MenuContainer = styled.nav`
  width: ${MENU_WIDTH};
  //flex: 1 0;
  text-align: right;
  white-space: nowrap;
  background-color: white;
  border-radius: 8px 0 0 0;
  padding: 0 20px;
  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
`;

export const Link = styled(RRLink)`
  display: block;
  text-decoration: none;
  font-weight: 200;
  color: black;
  margin: 8px 12px 8px 0;
  max-width: 100%;
  white-space: normal;
  &.current {
    color: red;
  }
  &:hover {
    color: red;
  }
  &.primary {
    font-weight: 600;
  }
`;

export const NavButton = styled.a`
  display: block;
  text-decoration: none;
  font-weight: 600;
  color: black;
  margin: 8px 12px 8px 0;
  border: none;
  background-color: transparent;
  font-size: 1em;
  padding: 0;
  cursor: pointer;
  line-height: 24px;
  &:hover {
    color: coral;
  }
`;

export const LanguagesContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  justify-content: center;
`;

export const LanguageButton = styled(NavButton)`
  &.current {
    color: red;
  }
`;
