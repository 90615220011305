export class FullscreenViewService {

    static readonly instance = new FullscreenViewService();
    private callbacks: Callback[] = [];

    private constructor() {

    }

    subscribe(callback: Callback): void {
        this.callbacks.push(callback);
    }

    unsubscribe(callback: Callback): void {
        this.callbacks = this.callbacks.filter(c => c !== callback);
    }

    show(src: string, collection: string[] | null, label: string | null, description?: string) {
        this.callbacks.forEach(callback => callback(src, collection, label, description ?? null));
    }

}

type Callback = (src: string, collection: string[] | null, label: string | null, description: string| null) => void;
