import React from "react";
import * as S from "./styles";
import {useTranslation} from "react-i18next";
import image from "./images/1.png";

export const TextsPage: React.FC = () => {

    const {t} = useTranslation();

    return <div>
        <S.Title>{t("texts.title")}</S.Title>
        <S.Container>
            <p>
                Eine nackte, zum Skelett abgemagerte junge Frau krümmt sich in verdorrtem Herbstlaub.
                Daneben liegt eine Hochschwangere, notdürftig von weißer Gaze verhüllt, dem Blick des
                Betrachters schutzlos ausgeliefert, erdenschwer. Puppenhaft erstarrt streckt eine dritte
                Frau ihre Hände aus einer milchigen Flüssigkeit, in der sie zu versinken droht. Kann ein
                Gott die drei Frauen erlösen? Möglicherweise der engelhafte junge Mann darüber, der das
                Motiv der Apotheose verkörpert, die Erhebung des Menschen zum Göttlichen? Statt einer
                Wolke aus Licht, wie auf alten mythologischen Darstellungen, entsteigt er höchst irdischer
                Plastikfolie, eine ironische Brechung, die beabsichtigt ist.
            </p>
            <p>
                Rätselhaft, verstörend und von atemberaubender Schönheit sind die Bilder der Künstlerin
                Victoria Rosenman. Eigentlich wollte sich die gebürtige St. Petersburgerin nach ihrem
                Kunststudium in Basel der Malerei widmen, entdeckte dann jedoch das Medium
                Fotografie. Seither hat sie eine eigene künstlerische Form entwickelt, in der sie ein nahezu
                altmeisterliches Gespür für Licht, Form und Farbe mit einem seismographischen Gespür
                für Menschen verbindet. Abseits der Portraitfotografie entstehen Inszenierungen, mit
                denen sie ihre Modelle gleichermaßen entblößt wie überhöht.
            </p>
            <p>
                „Ich will herausfinden, wie intensiv sich ein Mensch einem anderen hingeben kann“, sagt
                Victoria Rosenman. „Das setzt großes Vertrauen voraus.“ Wer sich ihr ausliefert, lässt sich
                auf ein gewagtes Spiel ein – die Künstlerin spricht vom „Vernichten der Musen.“ Macht
                und Abhängigkeit, Vernichten und Erschaffen sind die Themen, die hinter den Bildern
                aufscheinen. Während die Musen der Antike Künstlern Schutz und Inspiration gewährten,
                sind sie hier Projektionsflächen, visuelles und psychisches Material. Es geht um
                Obsessionen, um das Ringen um Identität, auch um die Dekonstruktion gängiger
                Schönheitsideale. Der Annäherung an das Bild gehen deshalb lange Gespräche voraus.
                Die junge Frau im Herbstlaub beispielsweise hat der schonungslosen Zurschaustellung
                ihres Körpers nicht nur zugestimmt, sie wollte es so, erklärt die Künstlerin. „Sie ist
                magersüchtig, es war ihr Wunsch, sich so zu zeigen, und zwar mit Stolz.“
            </p>
            <p>
                Mittlerweile hat Victoria Rosenman mit vielen weiteren Modellen gearbeitet. „Das Bild ist
                nur ein Zwischenresultat. Das Allerwichtigste ist der Prozess, der dorthin führt, und was
                danach passiert – kommt eine zweite Phase? Entwickelt man sich gemeinsam weiter oder
                auseinander?“ So erzählen diese Bilder viel über Beziehungen, über
                Herrschaftsansprüche und Hingabe, über Konflikt und Passion. Das macht sie wahrhaftig,
                trotz der hochartifiziellen Ästhetik. Es geht um Existenzielles, und das schließt die Rolle
                der Künstlerin ein, die sich bewusst ist, wie tief sie in intimste seelische Zonen ihrer
                Musen vordringt: „Ich beschäftige mich mit Fragen nach Gut und Böse, Schuld und
                Unschuld, Opfer und Täter.“
            </p>
            <p>
                Die kreuzförmige Hängung in der aktuellen Ausstellung deutet eine religiöse Archetypik
                an, die Raum für Spekulationen lässt. Werden diese Musen auf dem Altar der
                ästhetischen Verwandlung geopfert? Oder verleiht ihnen die Bildsprache eine eigene,
                ungekannte Würde? Zumindest ist erkennbar, dass der Grad des Objekthaften variiert.
                Die Frau, die auf der unteren Arbeit selbstbewusst in die Kamera schaut, scheint in sich
                zu ruhen, der Kamera ihre Persönlichkeit entgegenzusetzen. Ein Duell auf Augenhöhe,
                Victoria Rosenman nennt sie eine Gewinnerin. Die drei Frauen in der Waagerechten
                hingegen liefern sich aus, um den Preis der Leblosigkeit aufgespießter Schmetterlinge.
                Einzig der engelhafte junge Mann auf dem oberen Bild scheint sich indiskreten Eingriffen
                zu entziehen, den Blick himmelwärts gerichtet.
            </p>
            <p>
                Ob aber Gewinner und Verlierer wirklich feststehen, ist nicht endgültig zu entscheiden.
                Was gelungen genannt werden kann, was Glück sei, diese Fragen lässt Victoria
                Rosenman offen, indem sie auch dem Zweifel, der Kalamität, dem Leiden eine eigene
                Schönheit verleiht. Ihre Bilder sind Psychogramme, die Dramen offenbaren, aber auch
                Ambivalenzen zulassen – trotz der akribischen kompositorischen Vorarbeit. Das gibt den
                Bildern etwas Kontemplatives, nicht Greifbares, was sie im besten Sinne beunruhigend
                macht.
            </p>
        </S.Container>
        <S.Container>
            <img alt="texts for specific works illustration" src={image} style={{width: "100%"}}/>
        </S.Container>
        <S.Container>
            <p>
                „Denn das Schöne ist nichts als des Schrecklichen Anfang“, dichtete Rilke in
                seiner ersten Duineser Elegie. Ein dunkler, beunruhigender Satz, der miten
                hineinführt in den verstörend schönen Bilderkosmos der Künstlerin Victoria
                Rosenman. Ihr Leitmotv ist der verwandelnde Blick gegenseitger Erforschung
                von Künstlerin und Muse. Wer bist du? Was siehst du in mir? Was könnte ich
                für dich sein? Und: Was wäre ich ohne dich?
            </p>
            <p>
                Wie in der experimentellen Quantenphysik lösen sich dabei die Grenzen
                zwischen Beobachter und Beobachtetem auf. Die Kamera, einst Sinnbild
                objektvierender Distanz, erlaubt keine Unterscheidung mehr zwischen
                künstlerischem Subjekt und dargestelltem Objekt. Statdessen liefern sich
                Künstlerin und Muse einander aus. Machtspiele werden initiert, Abgründe
                ausgelotet, intme Momente absoluter Vertrautheit entstehen. Nicht von
                ungefähr ist den Bildern daher eine rätselhafe Erotk zu Eigen. Es geht um eine
                ewig menschliche Erfahrung: die hochriskante, schrecklich illusionäre Schönheit
                von Beziehungen.
            </p>
            <p>
                Victoria Rosenman zeigt: Jede wahrhafige, ekstatsche, entgrenzte Begegnung
                ist grandioser Schöpfungsmoment und kleiner Tod zugleich, le pett mort, wie
                Franzosen den Orgasmus nennen. In diesem erotschen Wechselspiel des
                Findens und Verlierens vereinigen sich erschafende und zerstörerische Kräfe
                zu einem ergebnisofenen Prozess. Musen seien die Refexionsfächen ihrer
                Visionen, sagt Victoria Rosenman, das Ziel sei Glückseligkeit. Natürlich weiß sie,
                und das belegt der Generalttel „Vom Vernichten einer Muse“, dass solches
                Glück mit Entblößungen verbunden ist, mit Hingabe, die an Selbstaufgabe
                heranreicht. Niemand bleibt unantastbar während der künstlerischen
                Transformaton, das betont Victoria Rosenman, jede Muse muss sich ihren
                schlimmsten Ängsten aussetzen, ihren Phobien ins Auge blicken, und so
                amalgamiert sich das Schöne und das Schreckliche zu einer hart errungenen
                Wahrheit.
            </p>
            <p>
                Das ist neu. Traditonell beruhte das Verhältnis von Künstler und Muse auf
                einem imaginären Kontrakt: Die Muse gewährte huldreich Inspiraton, der
                Künstler empfng sie dankbar und feierte die Muse durch sein Werk. Diese Win-
                Win-Idylle sprengt Victoria Rosenman. Radikal bricht sie mit der Vorstellung
                prästabilisierter Harmonie im Verhältnis zur Muse und erweitert die Beziehung
                zur zärtlichen Kampfzone mit hochästhetschen Detonatonen. In der im
                Wortsinne überwältgenden Schönheit ihrer Inszenierungen lauern deshalb
                noch ganz andere Fragen: Wie weit wirst du gehen? Was lässt du zu? Wie
                entlädt sich die emotonale Spannung? Erreichen wir die kontemplatve Ebene
                absoluter Kunst, oder stürzen wir ab?
            </p>
            <p>
                So entstehen faszinierende, altmeisterlich arrangierte Bilder, die noch lange im
                Augenhintergrund weiter irrlichtern, wenn Farben und Formen längst
                vorübergezogen sind. Ein Element dieser Faszinaton ist die überzeitliche
                Mythensphäre, in die Victoria Rosenman entührt. Ihre Musen sind einzigartge,
                unverwechselbare Persönlichkeiten, keine zeitgeschmackskompatblen Models.
                Bei aller Individualität scheinen sie darüber hinaus jedoch Vertreter einer
                zauberischen Zwischenwelt zu sein, bevölkert von Nymphen und Feen, Faunen
                und Halbgötern. Exemplarisch für diese mythische Aufadung sind die vier
                Geschöpfe im Wald, gleichsam willkürlich ausgesetzt und doch widerständig ihr
                eigenes Kräfefeld behauptend. Jeder verbirgt sich hinter der Sichtbarkeit, jeder
                bewahrt sein Geheimnis, auch die Künstlerin, links im Bild, die zum surrealen
                Zeichen ihrer wirkmächtgen schwarzweißen Magie wird. Das Straußenei,
                sinnhafes Zentrum der Bildkompositon, deutet die Aufösung der realen
                Existenz und die Wiedergeburt als Muse in einer höheren Daseinsform an.
            </p>
            <p>
                Das erzeugt aparte Ambivalenzen. So wie bei dem jungen Mann, der als
                ermatete Konkursmasse durchzechter Nächte zwischen Flaschen auf einem
                Tisch liegt, ein erschöpfer, ausgelaugter Bacchus. Im nächsten Bild wiederum
                stellt er als klassischer Narziss seine intakte Schönheit aus, bis an die
                Schmerzgrenze süßlichbunter Oberfächlichkeit – Sinnbild der Verführung und
                Täuschung durch schönen Schein, zugleich ein moderner Gilles von
                Wateau’scher Melancholie. Seine nackte Haut aber macht ihn verwundbar, ein
                Musenprinzip, das Victoria Rosenman in mehreren Bilderfndungen variiert. Sei
                es mit der rothaarigen Elfe, die sich in kostbaren Stofen rekelt, sei es mit der
                dunkelhaarigen Sirene, die der collagierten, in sich verschachtelten Palazzo-
                Architektur ihre kreatürliche Existenz entgegensetzt.
            </p>
            <p>
                Einem antken Mythos zufolge stand die Hetäre Phryne als Angeklagte einer
                Gerichtsverhandlung nackt vor ihren Richtern, um diese von ihrer Unschuld zu
                überzeugen. Es war ein beliebtes Motv für Maler des 18. und 19. Jahrhunderts.
                Die nackte Schönheit als Beweis unschuldiger Wahrhafigkeit, nach wie vor
                schwingen solche Assoziatonen mit, wenn wir Victoria Rosenmans Bilder
                betrachten. Ihr Musenkonzept steigert diesen Eindruck. Nackte Haut
                signalisiert Schutzlosigkeit, aber auch Stärke. Den Kamerablick auszuhalten, der
                sich flterlos darauf richtet, die Lust am eigenen Exhibitonismus zu ofenbaren
                und auf die soziale Distnkton durch Kleidung zu verzichten, erfordert eine
                geradezu atemberaubende Bereitschaf, auf sich selbst zurückgeworfen zu
                werden. Man ahnt in solchen Bildern den langen Entstehungsprozess, die
                Vorarbeit, die Gespräche, die Stadien zaghaf wachsenden Vertrauens. Victoria
                Rosenman spricht von der notwendigen Bereitschaf der Muse, unbehagliche
                Zustände zuzulassen. Es seien wichtge Phasen der Verunsicherung, aus denen
                idealiter eine neue Kraf resultere.
            </p>
            <p>
                Diese Beobachtung deutet auf die Dimension der Zeit in Victoria Rosenmans
                Werk hin. Ästhetsch transformiert protokolliert sie Stadien der Beziehung, die
                Dynamik unabsehbarer Entwicklungen. Explizit wird das Moment der Zeit im
                Zyklus der Muse im grünen Samt. Es sind Miniaturen, deren Rahmen aus alten
                Uhrgläsern bestehen. Folgerichtg gemahnen Körperstellung und auch die
                Haltung der Gliedmaßen an Uhrzeiger, an geronnene Lebenszeit, im Bild
                festgehalten als Hommage an die Vergänglichkeit - Schönheit ist ein
                transitorisches Phänomen, so wie die heiklen Phasen der Musenbeziehung.
            </p>
            <p>
                Nichts bleibt, wie es nie war, lautet ein amüsant-sarkastsches Bonmot. Das gilt
                auch für Victoria Rosenmans Projekt. Deshalb erweist sich die Bindung an die
                Muse zuweilen als füchtg. Requiem Box nennt die Künstlerin eine Schachtel,
                die stat materieller Artefakte die Erinnerung an eine einstge Muse enthält. In
                die Trauer über das Entschwundene mischt sich das Bewusstsein, dass die
                Vergänglichkeit der schöpferischen Beziehung nie die vorhergehende
                Entwicklung auslöschen kann. Selbst die abwesende Muse ist noch präsent,
                denn sie erlaubt die Erkenntnis, dass Beziehungen, auch zwischen Künstlerin
                und Muse, von vornherein die eigene Instabilität in sich tragen. Insofern kann
                man die Abwesenheit ebenfalls als Resultat und Manifestaton der
                Transformatonsprozesse betrachten. Eine Vernichtung, die in neue
                Souveränität, in neue Freiheit mündet. Die schrecklich illusionäre Schönheit der
                Beziehung ofenbart sich als Notwendigkeit, einander loszulassen, bevor die
                Vernichtung existenziell werden könnte. Das ist das besondere Ethos in Victoria
                Rosenmans Musenuniversum: Künstlerische Schönheit wird ex post zur
                schützenden Geste. Oder, wie Rilke schreibt: „Denn das Schöne ist nichts als
                des Schrecklichen Anfang, den wir noch grade ertragen, und wir bewundern es
                so, weil es gelassen verschmäht, uns zu zerstören.“
            </p>
        </S.Container>
    </div>;

};
