import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {FullscreenViewService} from "../../services/fullscreen-view-service";

export const FullscreenView: React.FC = () => {

    const [currentImage, setCurrentImage] = useState<string | null>(null);
    const [currentLabel, setCurrentLabel] = useState<string | null>(null);
    const [currentDescription, setCurrentDescription] = useState<string | null>(null);

    useEffect(() => {
        const callback = (src: string, collection: string[] | null, label: string | null, description: string | null) => {
            setCurrentImage(src);
            setCurrentLabel(label);
            setCurrentDescription(description);
        };
        FullscreenViewService.instance.subscribe(callback);
        return () => FullscreenViewService.instance.unsubscribe(callback);
    }, []);

    const close = () => {
        setCurrentImage(null);
        setCurrentLabel(null);
        setCurrentDescription(null);
    };

    return <>
        {currentImage && <S.Overlay onClick={close}>
            <S.Image url={currentImage}/>
            {currentLabel && <S.Label>
                {currentLabel}
                {currentDescription && <S.Description>{currentDescription}</S.Description>}
            </S.Label>}
        </S.Overlay>}
    </>;

};
