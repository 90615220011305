import React from "react";
import * as S from "./styles";
import {useTranslation} from "react-i18next";
import image from "./images/img.jpeg";

export const ManifestosPage: React.FC = () => {

    const {t} = useTranslation();

    return <div>
        <S.Title>{t("manifestos.title")}</S.Title>
        <img style={{margin: "20px", maxWidth: "100%"}} src={image} alt="illustration for artist's manifestos"/>
        <S.Container>
            <S.Subtitle>Neue Formen und Memorial der eigenen Glückseligkeit</S.Subtitle>
            <p>
                Der Mensch wird zum Fundament meiner Kunst und unterliegt im künstlerischen Prozess einer
                Transformation , die nicht mit universeller physischer Wahrnehmung einhergeht. Diese
                Transformation wird von mir in greifbarer Form und als Endprodukt gezeigt.
            </p>
            <p>
                Sobald der Mensch zur Muse wird beginnt eine neue Form des Schaffens und des Wachstums der
                Kunst. Die Muse und der Künstler durchleben Phasen in denen sie ihre Positionen tauschen,
                Emotionen entwickeln, die die Authentizität der eigenen Persönlichkeit hervorbringen und somit
                zum Progress des Neuen beitragen. Die Phasen sind unabdingbar um eine neue Reflexionsfläche,
                anhand der Muse zu schaffen. Somit lebe ich meine Visionen an dieser Reflexionsfläche aus und
                dadurch wird eine weitere neue Form geschaffen. Die Muse ist ein Werkzeug um Illusionen zu
                manifestieren, denn meine Visionen sind befreit von Logik und Realität ( sofern es beides gibt ),
                haben keine klaren Intentionen und Botschaften nach Aussenwelt, sondern dienen ausschliesslich
                der eigenen Glückseligkeit und dem eigenen Wohlergehen. Die Zufriedenheit, die durch diese
                Visionen entsteht ist als reales Gefühl nicht definierbar, die Wirkung der daraus resultierenden
                Wohlgefühls bleibt aber dieselbe. Diese Art von Glückseligkeit ist notwendig für die Kunst und ist
                somit fest mit Illusion ( auch als Phantasie zu bezeichnen ) verbunden. Ich manifestiere Illusionen,
                denn diese bringen mich zur neuen Formen und zur Kunst. Meine Illusionen lebe ich in der Muse
                aus. Ich erschaffe greifbare Form, die ich, als Dokumentation des künstlerischen Prozesses
                festhalte. Die greifbare Form ist eine Erinnerung an eigene Glückseligkeit und Transformation der
                Muse. Diese Form ist ein Abbild des Menschen, der zur Muse auserwählt und Inhalt eigener
                Illusionen geworden ist.
            </p>
        </S.Container>
        <S.Container>
            <S.Subtitle>Prädestination und Transformation einer Muse</S.Subtitle>
            <p>
                Ein Mensch der zur Muse wird, muss im Inneren Polaritäten aufweisen, die stark reizen. Äussere
                Erscheinung soll ebenfalls zu der Wirkung einer vollkommenen Diskrepanz führen, die innerhalb
                eines Charakters erkennbar ist. Die Muse darf also nur „im Reinen mit sich selbst sein“, wenn das
                Streben nach der Harmonie einer Obsession gleicht. Alle Störungen und Ängste der Muse sind
                Instrumente zum Auslösen des künstlerischen Prozesses und dienen zur Erschaffung der neuen
                Form.
            </p>
            <p>
                Die Muse und der Künstler sollen eine Beziehung anstreben, die nicht auf physischer
                Anziehungskraft basiert, sondern eine höhere, kontemplative Ebene erreicht. Diese Beziehung, die
                bestehen kann und bleibt, die widerspruchslos beide, sowohl den Künstler, als auch die Muse
                vielseitig herausfordert und beansprucht, ohne der gegenseitigen Liebe im klassischen Sinne, wird
                zur absoluten Kunst aufgrund ihres utopischen Charakters. Das Streben nach Utopie ist daher
                notwendig, denn es ist a priori eine Handlung, die erschöpfend ist, Denkprozesse auslöst und zur
                Erkenntnissen führt, die unmittelbar zur Weiterentwicklung der Persönlichkeit beitragen. Die Mühe,
                die dabei entsteht ist dieselbe Mühe, die man beim erschaffen eines wahren Kunstwerks verspürt
                und ist daher mit absoluten Kunst gleichzusetzen.
                Eine Hingabe seitens der Muse führt zu ihrer Entblössung und wird künstlerisch genutzt. Sobald die
                vom Künstler verlangte Hingabe für die Anfangsform der Muse vernichtend wirkt, erschafft der
                Künstler eine neue Prädestination für diese Muse, eine weitere Kausalität, die zum künstlerischen
                Endergebnis beiträgt.
            </p>
        </S.Container>
        <S.Container>
            <S.Subtitle>Die Vergänglichkeit der Muse und die Requiem – Box</S.Subtitle>
            <p>
                Muse und Künstler agieren instinktiv: der Aufenthalt der Muse im künstlerischen Prozess wird nicht
                vorher bestimmt. Die Wandlung der Intensität über Notwendigkeit der jeweiligen Muse ist
                willkürlich und immer temporär. Das Verlangen das Dasein der Muse festzuhalten und daraus
                resultierende Instabilität der Prognose über den künstlerischen Erfolg sind Auslöser einer
                künstlerischen Spannung und gar eines Ausnahmezustands, der zur Radikalität führt, die in der
                absoluten Kunst unabdingbar ist. Die Freiheit der Muse und ihre potenzielle Transformation ist
                nicht nur für die materielle Visualisierung, sondern auch für die Requiem- Box entscheidend. Die
                Requiem Box ist eine reale Schachtel, dessen Inhalt nicht real (sofern man von Realität sprechen
                kann) ist. Den Inhalt der Requiem – Box füllt die vom Künstler erfahrene Abwesenheit einer
                besonderen Muse, deren Anwesenheit schon zu erfahren war . Eine Akzeptanz der plötzlichen
                Vergänglichkeit der Muse wird mit dem Empfinden des Trauerns nach der Schönheit und
                Glückseligkeit verglichen, dessen Existenz nicht bewiesen, sondern von dem Künstler womöglich
                nur erfunden wurde. Diese bewusste Wahrnehmung der Trauer nach Etwas, dessen Greifbarkeit und
                Realität infrage gestellt wird, bezeichne ich als Requiem. Dieses Requiem erhält seinen Platz in der
                Requiem Box. Das Requiem-Box ist ein essentieller Bestandteil der Kunst „vom Vernichten einer
                Muse“.
            </p>
        </S.Container>
        <S.Container>
            <S.Subtitle>Illusionierte Wahrheit deren Ursprung die Muse ist</S.Subtitle>
            <p>
                Die Endtransformation der Muse ist mein Kunstwerk. Sie ist ein aktives Instrument und bleibt
                nicht in der Position der Inspirationsquelle verhaftet. Die Muse ist kein Antrieb zu neuen Ideen,
                sondern transformiert sich zur ganzen Kunst. Um die Transformation erfolgreich zu vollenden muss
                die Muse bereit sein ihren Ängsten und Phobien zu stellen und diese möglichst intensiv zu erfahren.
                Sobald die Muse ihre Unantastbarkeit aufgibt übergeht sie in eine neue Phase und gewinnt an mehr
                Kraft. Diese Kraft wird ab diesem Moment immer in ihrem Besitz bleiben und der Künstler kann
                sie ebenfalls für sich verwenden.
                Der Mensch, der zur Muse wird, hat das Privilegium eine hohe Prädestination zu erlangen d.h.ein
                Teil von sich preiszugeben, der zu Kunst und zu einer illusionierten Wahrheit wird.
            </p>
        </S.Container>
        <S.Container>
            <S.Subtitle>Illusionierte Wahrheiten als Kunst</S.Subtitle>
            <p>
                Die Definition der Wahrhaftigkeit in der Kunst, als Beurteilungskriterium für die Qualität, leidet an
                Subjektivität des Rezipienten. Die Wahrhaftigkeit der Kunst ( wird auch als Definition „wahrer
                Kunst“ verstanden) kann keinen universellen Konsens finden, weil es eine Tiefe bzw. einen
                Untergrund besitzt, die nicht mit der individuellen Ästhetik jedes einzelnen Subjekts nivelliert
                werden kann. Daraus folgt, dass eine publik gemachte oder anerkannte Wahrhaftigkeit der Kunst,
                oft nur die Patina einer weiteren Unterschicht ist, und somit nicht richtungsweisend oder beurteilbar
                sein kann, da sie entfernbar ist. Die Unterebenen der Patina können ins Unendliche reichen und
                selten ganz erkannt werden. Das bedeutet, dass jeglicher Darstellung keine omnipotente
                Wahrhaftigkeit über ihre Essenz zugeschrieben werden kann und es ist nutzlos eine Wertung den
                Kriterien der Authentizität und Wahrheit anzupassen. Ich werde somit keine Versuche unternehmen
                eine klar wahrzunehmende Realität anhand der gegeben Realität zu schaffen um zu einer gewissen
                universellen Feststellung beizutragen. Meine Illusionen werden vorangetrieben um eine Mehrzahl
                der Wahrheiten zu bieten, die jedem wahrnehmenden Subjekt die Freiheit lässt, unterschiedliche
                Realitäten und daraus resultierende Illusionen zu geniessen.
            </p>
        </S.Container>
        <S.Container>
            <S.Subtitle>Die Muse als Material mit Vernunft</S.Subtitle>
            <p>
                Die Muse hat in meiner Kunst eine externe materielle Anfangsform und zwar die des Menschen.
                Der Verstand des Menschen wird zum Konstrukt der Grenzzustände der Muse, dessen Wirkung
                später in Kunst umgewandelt wird. Determination jeglicher Akte bzw. Handlungen die durch
                Vernunft geschieht ist der Schnittpunkt zwischen Entwicklung und Degradation. Um den
                künstlerischen Prozess auszulösen muss die Muse dazu herausgefordert werden, diese Polaritäten zu
                erkennen, um eigene determinierende Grenzen zu vernichten. Die Vernichtung dieser Grenzen
                passiert nur dann, wenn die Muse bereit ist, einem Zustand der Unbehaglichkeit zu verfallen, der
                durch den Künstler provoziert wird. Der Zustand der bewussten Indisposition kann heute nur bei
                einem Wesen mit Vernunft erreicht werden und daher ist der Mensch als Muse die Voraussetzung
                dafür.
            </p>
        </S.Container>
    </div>;

};
