import styled, {keyframes} from "styled-components";
import {fadeInUp} from "react-animations";
import {ProgressiveImage} from "../ProgressiveImage/ProgressiveImage";

const animation = keyframes`
  ${fadeInUp}
  0% {
    transform: translate3d(0, 15%, 0);
  }
`;

export const Image = styled(ProgressiveImage)`
  animation: 1s ${animation};
  width: 100%;
`;
