import React, {useEffect, useRef, useState} from "react";
import * as S from "./styles";
import {Route, Routes} from "react-router-dom";
import {Page} from "../../models/pages";
import {HomePage} from "../HomePage/HomePage";
import {Menu} from "../Menu/Menu";
import {AboutPage} from "../AboutPage/AboutPage";
import {ContactsPage} from "../ContactsPage/ContactsPage";
import {WorksPage} from "../WorksPage/WorksPage";
import {MusesPage} from "../MusesPage/MusesPage";
import {ConceptPage} from "../ConceptPage/ConceptPage";
import {FullscreenView} from "../FullscreenView/FullscreenView";
import {FeaturePage} from "../FeaturePage/FeaturePage";
import {ScrollButton} from "../ScrollButton/ScrollButton";
import {TextsPage} from "../TextsPage/TextsPage";
import {ManifestosPage} from "../ManifestosPage/ManifestosPage";

const MOBILE_WIDTH = 768;

export const AppRoot: React.FC = () => {

    const [height, setHeight] = useState(window.innerHeight);
    const contentContainerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const [scrollTarget, setScrollTarget] = useState<HTMLElement | null>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);

    useEffect(() => {
        const target = isMobile ? menuRef.current : contentContainerRef.current;
        if (target) {
            setScrollTarget(target);
        }
    }, [contentContainerRef, menuRef, isMobile]);

    useEffect(() => {
        window.onresize = () => {
            const height = window.innerHeight;
            setIsMobile(window.innerWidth <= MOBILE_WIDTH);
            // console.log("resized", height);
            setHeight(height);
        };
    }, []);

    return <>
        <S.RootContainer height={height} id="layout-root">
            <S.Main style={{direction: "rtl"}}>
                <div ref={contentContainerRef} style={{direction: "ltr", minHeight: "1px"}}>
                    <Routes>
                        <Route index element={<HomePage/>}/>
                        <Route path={Page.HOME} element={<HomePage/>}/>
                        <Route path={Page.ABOUT} element={<AboutPage/>}/>
                        <Route path={Page.CONTACTS} element={<ContactsPage/>}/>
                        <Route path={Page.WORKS} element={<WorksPage/>}/>
                        <Route path={Page.MUSES} element={<MusesPage/>}/>
                        <Route path={Page.CONCEPT} element={<ConceptPage/>}/>
                        <Route path={Page.FEATURE} element={<FeaturePage/>}/>
                        <Route path={Page.TEXTS} element={<TextsPage/>}/>
                        <Route path={Page.MANIFESTOS} element={<ManifestosPage/>}/>
                    </Routes>
                </div>
                <div id="anchor" style={{height: "0px", width: "1px", transform: "translateY(-1px)"}}/>
            </S.Main>
            <Menu ref={menuRef}/>
        </S.RootContainer>
        <FullscreenView/>
        {scrollTarget && <ScrollButton container={scrollTarget}/>}
    </>;

};
