import React from "react";
import * as S from "./styles";

export const ContactsPage: React.FC = () => {

    return <>
        <S.Container>
            <a href="mailto:rosenmanvictoria@gmail.com">rosenmanvictoria@gmail.com</a>
        </S.Container>
    </>;

};
