import React from "react";
import * as S from "./styles";
import image from "./images/com.jpg";
import imageMini from "./images/com-mini.jpeg";
import imageNano from "./images/com-nano.jpeg";
import {FullscreenViewService} from "../../services/fullscreen-view-service";
import {ProgressiveImage} from "../ProgressiveImage/ProgressiveImage";
import {useTranslation} from "react-i18next";

export const ConceptPage: React.FC = () => {

    const {t} = useTranslation();

    return <div style={{position: "static"}}>

        <ProgressiveImage
            src={image}
            minifiedSrc={imageMini}
            nanoSrc={imageNano}
            style={{width: "100%", display: "block", boxSizing: "border-box"}}
            onClick={() => FullscreenViewService.instance.show(image, null, null)}
        />

        <S.Text style={{backgroundColor: "white"}}>
            <S.Title>{t("concept.title")}</S.Title>
            <p>{t("concept.paragraph")}</p>
        </S.Text>
    </div>;

};
