import React from "react";
import * as S from "./styles";
import image from "./images/featureproject.jpg";
import imageMini from "./images/featureproject-mini.jpeg";
import imageNano from "./images/featureproject-nano.jpeg";
import {FullscreenViewService} from "../../services/fullscreen-view-service";
import {ProgressiveImage} from "../ProgressiveImage/ProgressiveImage";
import {useTranslation} from "react-i18next";

export const FeaturePage: React.FC = () => {

    const {t} = useTranslation();

    return <div style={{position: "static"}}>

        <div onClick={() => FullscreenViewService.instance.show(image, null, null)}>
            <ProgressiveImage
                src={image}
                minifiedSrc={imageMini}
                nanoSrc={imageNano}
                style={{width: "100%", position: "absolute", top: 0, zIndex: -10}}
            />
            <img src={imageMini} style={{width: "100%", visibility: "hidden"}} />
        </div>

        <S.Text style={{backgroundColor: "white"}}>
            <S.Title>{t("project.title")}</S.Title>
            <p>{t("project.paragraph1")}</p>
            <p>{t("project.paragraph2")}</p>
            <p>{t("project.paragraph3")}</p>
        </S.Text>
    </div>;

};
