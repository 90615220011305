import styled from "styled-components";
import {ProgressiveImage} from "../ProgressiveImage/ProgressiveImage";

export const NameLabel = styled.h1`
  font-size: 1.2em;
  display: block;
  margin: 50px 40px;
  padding: 6px 6px;
  font-weight: 600;
  border-bottom: 3px solid black;
  width: fit-content;
`;

export const HomeContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
`;

export const Image = styled(ProgressiveImage)`
  height: 100%;
  width: 100%;
`;
