export enum Page {
    HOME = "/home",
    ABOUT = "/about",
    CONTACTS = "/contacts",
    WORKS = "/works",
    MUSES = "/muses",
    CONCEPT = "/concept",
    FEATURE = "/feat",
    TEXTS = "/texts",
    MANIFESTOS = "/manifestos",
}
