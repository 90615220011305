import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import {storage} from "./storage";

export enum Language {
    EN = "en",
    DE = "de",
}

export function initI18next() {
    i18next
        .use(initReactI18next)
        .init({
            resources,
            lng: storage.getLang(),
            interpolation: {
                escapeValue: false,
            },
        });
}

const resources = {
    en: {
        translation: {
            menu: {
                home: "Home",
                works: "Main Works",
                texts: "Texts About Specific Works",
                manifestos: "Artists’s Manifestos",
                art: "Art",
                muses: "Melting Muses",
                concept: "Concept For Mitigations",
                project: "Feature Project",
                about: "About",
                contacts: "Contacts",
            },
            muses: {
                title: "The melting muses",
                paragraph1: "The current project addresses intersubjective relations, which underlie the alternating hierarchies. Attitudes of the artist and her muses become the ground for the creative process and the hustling power of the project.",
                paragraph2: "The personal muses of the artist are people of differing spiritual and physical essence, who are united by significant traits, which define the artist, Victoria Rosenman. This essence exposes the polarity of perception, revealing to the observer the unhidden fears and contradictions. From times to times new characters enter the scene and a new story evolves – a story of temptation and angst, reflected in photographs and installations.",
                paragraph3: "The ambivalence of the characters: beauty and ugliness, penance, sacrifice and guilt are represented through photography. The artist speaks of the burden of each muse, the skinny, the pregnant, the beautiful and the ephemeral. She speaks of fears, madness, doubt or a journey for ones’ self. Gradually, the artist becomes enmeshed in their world, through experiencing their feelings. They co-exist, emotionally involved to the extent of being able to ruin themselves. Mutual trust, evolving between the artist and the muses, the loss of control causing new wave of fears, drawing each one of them to the authentic self.",
                paragraph4: "The alternating roles and the mutual inception serves as a significant component of the artistic process. It is a conscious rather than arbitrary, drawing the artist as well as the muses into the various meaning of existence. The blurred boundaries of canonized perceptions are defined from anew.",
                paragraph5: "The final phase of a journey for the artist, is presenting the muses, real people, their photographic representation through installations. The Muses, a live composition are destined to endure the penetrating eye of the visitors.",
                paragraph6: "The comparison of the artistic perspective with reality embodied by a human. The essence of the muse is erased by this comparison. The installation helps to redefine our feelings provoked by the work and personal attitudes through installation. It emphasizes the dissonance between perception and one’s personality.",
                paragraph7: "Therefore, the artist exclaims her wish to reconcile the illusions, and craves for the observer to see the ways in which he/she should perceive their own essence.",
                paragraph8: "The project evolves regardless of time and place. The installations are fulfilled in various spaces, all representing alternating or hybrid states. This is how the exhibition becomes a “history of stories”.",
                paragraph9: "New muses reveal and hide themselves from the artist. Those embody the perception of the temporary and provoke a feeling of requiem, and fear of the unknown.",
                paragraph10: "The borderline states, values new and old, personal attitudes, the transformation of habituated standards develop further and cast doubt on the components of contemporary reason. The artist strives to facilitate a process of self-manifestation, avoiding hesitation, placating the inner strength, which can overcome the indifference to the human.",
            },
            concept: {
                title: "Concepts for mitigation",
                paragraph: "The time it is no longer about the muse, as flesh and blood inspiration – or exploring interpersonal relationships. The title “Concept for Mitigations” already involves the current idea. If you look for a synonym for the word mitigation, terms like “alleviation”, “relief”, “defusing” or “encouragement” come up. This time I am developing ( utopian ) solutions, advice, examples and prompts in the form of illustrations, objects and texts that “downplay” our human existence in a good sense or make it more pleasant. This time I am the mad doctor who produces and presents a medicine that is magical if you “accept” it and believe in it.",
            },
            project: {
                title: "DELICATE WORK PROJECT",
                paragraph1: "Artist Victoria Rosenman now cooperates as a contractor for the brave, the experimental and the curious. She offers new perspective on the self and as a result she produces a representation of the individual, as an autonomous work of art, which can have many functions: Reflection, memory, gift, satisfaction, art as such.",
                paragraph2: "As a photo assignment, an extraordinary self-portrait or a staging with several people is created. Victoria Rosenman discusses details and preferences, desires and fears, that the photograph becomes a unique work of art, triggers a thought process and gains maximum authenticity.",
                paragraph3: "Prices vary with the concept. Just contact us.",
            },
            about: {
                title: "About",
                paragraph1: "Victoria Rosenman’s art tells of an eternally human experience: the high-risk, horribly illusory beauty of relationships, power and dependence, destruction and creation, obsession and surrender. The leitmotif is the transformative gaze of mutual exploration between the artist and the people surrounding her. Who are you? What do you see in me? What could I be for you? And: What would I be without you? How far will you go? What will you allow? How do you release the emotional tension? Do we reach the contemplative level of absolute art, or do we crash? It is photo stagings, objects, texts ( manifestos ) and especially installations with real people that reveal the process of people’s necessary willingness to allow uncomfortable states. The artistic process is more important than the final result. It is interpersonal phases of uncertainty that ideally result in a new strength.",
                paragraph2: "Victoria Rosenman (born 1986 in St. Petersburg) lives and works in Berlin. After studying art in Switzerland, she received a PIC Selected scholarship for her project About Destroying a Muse. She works with multimedia and her works are represented in private collections, galleries and museums.",
            },
            texts: {
                title: "Texts by Dr. Chtristine Eichel",
            },
            manifestos: {
                title: "Manifestos",
            },
        },
    },
    de: {
        translation: {
            menu: {
                home: "Home",
                works: "Die wichtigsten Werke",
                texts: "Texte zu Bestimmten Arbeiten",
                manifestos: "Manifeste der Künstlerin",
                art: "Kunst",
                muses: "Vom Vernichten einer Muse",
                concept: "Konzepte für Milderung",
                project: "Feature Project",
                about: "Über die Künstlerin",
                contacts: "Kontakt",
            },
            muses: {
                title: "Vom Vernichten einer Muse",
                paragraph1: "Dieses Projekt erzählt von zwischenmenschlichen Beziehungen, die einer ständig wechselnden Hierarchie unterliegen. Die Beziehung zwischen der Künstlerin und ihren Musen bildet die Basis für den künstlerischen Prozess und weitere Projektentwicklung.",
                paragraph2: "Menschen, die die Künstlerin, als ihre Musen bezeichnet, sind Persönlichkeiten unterschiedlicher geistiger und physischer Verfassung, die alle eine Gemeinsamkeit haben, den wichtigsten Aspekt des Kunstschaffens von Victoria Rosenman: sie weisen extreme Polaritäten innerhalb ihres Charakters auf und sind bereit daraus entstehende Widersprüchlichkeit und Ängste der eigenen Persönlichkeit zu offenbaren, in dem sie sich fremden Blicken ausliefern. Sobald die Künstlerin diese Menschen findet, beginnt ihre gemeinsame Geschichte, vom Reiz und Schmerz, die durch Fotografien und Installation dokumentiert wird.",
                paragraph3: "Auf Fotografien sind Ambivalenzen dieser Menschen zu sehen: Schönheit und Hässlichkeit, Schuld und Unschuld, Opfer und Täterschaft. Dabei geht die Künstlerin von der vorhanden Bürde jeder Muse aus, denn auf den Fotos sind abgemagerte, schwangere, schöne und fast ausserirdische Musen zu sehen. Sie alle haben Ängste, Störungen, Zweifel oder suchen sich selbst. Darauf lässt sich die Künstlerin ein und versucht dieselben Gefühle, zu durchleben, wie die ihrer Musen. Sie spüren es zusammen, sie können sich verlieben, sie können sich aber auch zerstören. Das gegenseitige Vertrauen, das zwischen der Muse und der Künstlerin entsteht führt zum Kontrollverlust und neuen Angstzuständen, die alle Beteiligten der eigenen Authentizität nähert.",
                paragraph4: "Der Wechsel der eigenen Position und das gegenseitige „Hineinversetzen“ in sein Gegenüber, ist ein wichtiger Teil des Prozesses. Er passiert bewusst und unbewusst und wirft sowohl die Künstlerin, als auch ihre Musen in verschiedene Rollen des Daseins. Das löst die Grenzen der bestehenden Vorstellung über die eigene Persönlichkeit auf und definiert sie neu.",
                paragraph5: "Als Endergebnis und um eine neue Etappe anzufangen, präsentiert die Künstlerin ihre Musen, echte Menschen, neben ihren fotografischen Abbildern, als Gesamtinstallation. Die Musen müssen als lebende Kunstwerke den Blicken des Betrachters standhalten.",
                paragraph6: "So passiert ein direkter Vergleich zwischen der Vision der Künstlerin und der Realität, die als echter Mensch, daneben agiert. Die Essenz der Muse, so wie sie auf den Fotografien wirkt, wird durch diesen Vergleich zerstört. Die Künstlerin hinterfragt unsere Empfindungen bezüglich der Bilder und Meinungen die wir tagtäglich konsumieren. Sie entblößt die Diskrepanz zwischen ihrer Wahrnehmung und der eigentlichen Person dahinter.",
                paragraph7: "Sie manifestiert somit auch ihren Wunsch sich mit der Illusion zufrieden zu geben und gleichzeitig möchte sie, dass der Betrachter und ihre eigenen Musen feste Erkenntnisse erlangen, wie sie ihre Existenz positionieren möchten oder sollen.",
                paragraph8: "Das Projekt entwickelt sich stets unabhängig von Ort und Zeit. Die Installation passiert in unterschiedlichen Räumlichkeiten, die selbst Zwischenzustände, oder Hybride personifizieren. So wird die Ausstellung zu einer „Geschichte in der Geschichte“.",
                paragraph9: "Es kommen immer neue Musen dazu und verlassen die Künstlerin wieder, was die Erkenntnis der Vergänglichkeit, das Gefühl des Requiems unterstreicht und die Angst vor der Ungewissheit.",
                paragraph10: "Die grenzwertigen Zustände, neue und alte Wertevorstellungen und eine mögliche Wandlung der beständigen Standards werden in ihrem künstlerischen Prozess weiterentwickelt und vor allem als ein wichtiger Teil der modernen Vernunft in Frage gestellt. Mit diesem Projekt möchte die Künstlerin dazu beitragen, dass Menschen ihre Authentizität mehr erkennen und befestigen, sodass eine innere Stärke entsteht, die unsere Gleichgültigkeit für die Mitmenschen auslöscht.",
            },
            concept: {
                title: "Konzepte für Milderung",
                paragraph: "Diesmal geht es nicht mehr um die Muse, als Inspiration aus Fleisch und Blut - oder um die Erforschung zwischenmenschlicher Beziehungen. Der Titel \"Konzept für Milderung\" beinhaltet bereits die aktuelle Idee. Sucht man nach einem Synonym für das Wort Mitigation, so fallen Begriffe wie \"Linderung\", \"Entlastung\", \"Entschärfung\" oder \"Ermutigung\". Diesmal entwickle ich (utopische) Lösungen, Ratschläge, Beispiele und Aufforderungen in Form von Abbildungen, Objekten und Texten, die unser menschliches Dasein im guten Sinne \"herunterspielen\" oder angenehmer machen. Diesmal ist Victoria Rosenman der verrückte Arzt, der eine Medizin herstellt und präsentiert, die magisch ist, wenn man sie \"annimmt\" und an sie glaubt.",
            },
            project: {
                title: "Delicate Work Project",
                paragraph1: "Die Künstlerin Victoria Rosenman arbeitet jetzt als Auftragnehmerin für die Mutigen, die Experimentellen und die Neugierigen. Sie bietet eine neue Perspektive auf das Selbst und produziert als Ergebnis eine Darstellung des Individuums, als autonomes Kunstwerk, das viele Funktionen haben kann: Reflexion, Erinnerung, Geschenk, Befriedigung, Kunst als solche.",
                paragraph2: "Als Fotoauftrag entsteht ein außergewöhnliches Selbstporträt oder eine Inszenierung mit mehreren Personen. Victoria Rosenman bespricht Details und Vorlieben, Wünsche und Ängste, damit das Foto zu einem einzigartigen Kunstwerk wird, einen Denkprozess auslöst und maximale Authentizität gewinnt.",
                paragraph3: "Die Preise variieren je nach Konzept. Kontaktieren Sie uns einfach.",
            },
            about: {
                title: "Über die Künstlerin",
                paragraph1: "Victoria Rosenman stellt radikale Fragen an die Identitätsfindung und an den fragilen Prozess in der Ausrichtung der zwischenmenschlichen Beziehungen: “Wer bist du? Was siehst du in mir? Was könnte ich für dich sein? Was wäre ich ohne dich? Wie weit wirst du gehen? Was lässt du zu? Wie entlädt sich die emotionale Spannung? Erreichen wir die kontemplative Ebene absoluter Kunst oder stürzen wir ab?” Für die Künstlerin stellt ihr emotionales Gegenüber stets eine Muse dar und sie will dabei die Grenzen ausloten zwischen ihrer eigenen Fähigkeit zur Selbstaufgabe bis hin zur reinen Obsession. Diese Spannweite aus Illusion und Abhängigkeit bezeichnet sie selbst als “gewollte Verunsicherung”, aus der sie auch angesichts der letztendlich aus ihrer Sicht notwendigen “Vernichtung der Muse” neue kreative und radikale Kräfte freisetzen will.",
                paragraph2: "Victoria Rosenman lebt und arbeitet in Berlin. Nach ihrem Kunststudium in der Schweiz erhielt sie ein PIC Selected Stipendium für ihr Projekt About Destroying a Muse. Sie arbeitet mit Multimedia und ihre Werke sind in privaten Sammlungen, Galerien und Museen vertreten.",
            },
            texts: {
                title: "Dr. Christine Eichel über Arbeiten von Victoria Rosenman",
            },
            manifestos: {
                title: "Manifeste",
            },
        },
    },
};
