import React, {useEffect, useState} from "react";
import * as S from "./styles";
import classNames from "classnames";

interface Props {
    container: HTMLElement;
}

export const ScrollButton: React.FC<Props> = (props) => {

    const {container} = props;
    const [scrolledToBottom, setScrolledToBottom] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((x) => {
            const ratio = x[0].intersectionRatio;
            // console.log("ratio", ratio);
            setScrolledToBottom(ratio > 0);
        }, {
            threshold: [0, 0.2, 1],
        });
        observer.observe(document.querySelector("#anchor")!);
    }, [])

    const scroll = () => {
        container.scrollIntoView({
            block: "end",
            behavior: "smooth",
        });
    };

    return <S.Button onClick={scroll} className={classNames({hidden: scrolledToBottom})}>
        <S.ButtonLabel>⌄</S.ButtonLabel>
    </S.Button>;

};
