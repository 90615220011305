import React, {forwardRef, useState} from "react";
import * as S from "./styles";
import {Page} from "../../models/pages";
import classNames from "classnames";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Language} from "../../services/i18next";
import i18next from "i18next";
import {storage} from "../../services/storage";

export const Menu = forwardRef<HTMLDivElement>((_, ref) => {

    const location = useLocation();
    const {t, i18n} = useTranslation();

    const currentLanguage = i18n.language;

    const scrollToTheTop = () => {
        setTimeout(() => document.querySelector("main")!.scrollIntoView({behavior: "smooth", block: "start"}), 50);
    };

    const renderLink = (label: string, page: Page, primary?: boolean) => {
        const current = location.pathname === page;
        return <S.Link
            to={page}
            className={classNames({current, primary})}
            onClick={scrollToTheTop}
        >
            {label}
        </S.Link>;
    };

    const switchLanguage = (lang: Language) => {
        i18next.changeLanguage(lang);
        storage.saveLang(lang);
    }

    const renderLangButton = (lang: Language) => {
        const isCurrent = currentLanguage === lang;
        return <S.LanguageButton onClick={() => switchLanguage(lang)} className={classNames({current: isCurrent})} key={lang}>
            {lang.toUpperCase()}
        </S.LanguageButton>;
    };

    return <S.MenuContainer ref={ref}>
        {renderLink(t("menu.home"), Page.HOME, true)}
        <Panel label={t("menu.art")}>
            {renderLink(t("menu.works"), Page.WORKS)}
            {renderLink(t("menu.texts"), Page.TEXTS)}
            {renderLink(t("menu.manifestos"), Page.MANIFESTOS)}
            {renderLink(t("menu.muses"), Page.MUSES)}
            {renderLink(t("menu.concept"), Page.CONCEPT)}
        </Panel>
        {/*{renderLink(t("menu.project"), Page.FEATURE, true)}*/}
        {renderLink(t("menu.about"), Page.ABOUT, true)}
        {renderLink(t("menu.contacts"), Page.CONTACTS, true)}
        <hr/>
        <S.LanguagesContainer>
            {[Language.EN, Language.DE].map(renderLangButton)}
        </S.LanguagesContainer>
    </S.MenuContainer>;

});

interface PanelProps {
    children: React.ReactNode;
    label: string;
}

const Panel: React.FC<PanelProps> = (props) => {

    const {children, label} = props;
    const [expanded, setExpanded] = useState(false);

    const toggle = () => setExpanded(!expanded);

    return <div>
        <S.NavButton onClick={toggle}>{label}</S.NavButton>
        {expanded && children}
    </div>;

};
