import React from "react";
import * as S from "./styles";
import Masonry from "react-masonry-css";
import {FullscreenViewService} from "../../services/fullscreen-view-service";
import img001 from "./images/work1.jpg";
import img002 from "./images/work2.jpg";
import img003 from "./images/work3.jpg";
import img004 from "./images/work4.jpg";
import img005 from "./images/work5.jpg";
import img006 from "./images/work6.jpg";
import img007 from "./images/work7.jpg";
import img008 from "./images/work8.jpg";
import img009 from "./images/work9.jpg";
import img010 from "./images/work10.jpg";
import img011 from "./images/work11.jpg";
import img012 from "./images/work12.jpg";
import img013 from "./images/work13.jpg";
import img014 from "./images/work14.jpg";
import img015 from "./images/work15.jpg";
import img016 from "./images/work16.jpg";
import img017 from "./images/work17.jpg";
import img018 from "./images/work18.jpg";
import img019 from "./images/work19.jpg";
import img020 from "./images/work20.jpg";
import img021 from "./images/work21.jpg";
import img022 from "./images/work22.jpg";
import img023 from "./images/work23.jpg";
import img024 from "./images/work24.jpg";
import img025 from "./images/work25.jpg";
import img026 from "./images/work26.jpg";
import img027 from "./images/work27.jpg";
import img028 from "./images/work28.jpg";
import img029 from "./images/work29.jpg";
import img030 from "./images/work30.jpg";
import img031 from "./images/work31.jpg";
import img032 from "./images/work32.jpg";
import img033 from "./images/work33.jpg";
import img034 from "./images/work34.jpg";
import img035 from "./images/work35.jpg";
import img036 from "./images/work36.jpg";
import img037 from "./images/work37.jpg";
import img038 from "./images/work38.jpg";
import img039 from "./images/work39.jpg";
import img040 from "./images/work40.jpg";
import img041 from "./images/work41.jpg";
import img001Mini from "./images/work1-mini.jpeg";
import img002Mini from "./images/work2-mini.jpeg";
import img003Mini from "./images/work3-mini.jpeg";
import img004Mini from "./images/work4-mini.jpeg";
import img005Mini from "./images/work5-mini.jpeg";
import img006Mini from "./images/work6-mini.jpeg";
import img007Mini from "./images/work7-mini.jpeg";
import img008Mini from "./images/work8-mini.jpeg";
import img009Mini from "./images/work9-mini.jpeg";
import img010Mini from "./images/work10-mini.jpeg";
import img011Mini from "./images/work11-mini.jpeg";
import img012Mini from "./images/work12-mini.jpeg";
import img013Mini from "./images/work13-mini.jpeg";
import img014Mini from "./images/work14-mini.jpeg";
import img015Mini from "./images/work15-mini.jpeg";
import img016Mini from "./images/work16-mini.jpeg";
import img017Mini from "./images/work17-mini.jpeg";
import img018Mini from "./images/work18-mini.jpeg";
import img019Mini from "./images/work19-mini.jpeg";
import img020Mini from "./images/work20-mini.jpeg";
import img021Mini from "./images/work21-mini.jpeg";
import img022Mini from "./images/work22-mini.jpeg";
import img023Mini from "./images/work23-mini.jpeg";
import img024Mini from "./images/work24-mini.jpeg";
import img025Mini from "./images/work25-mini.jpeg";
import img026Mini from "./images/work26-mini.jpeg";
import img027Mini from "./images/work27-mini.jpeg";
import img028Mini from "./images/work28-mini.jpeg";
import img029Mini from "./images/work29-mini.jpeg";
import img030Mini from "./images/work30-mini.jpeg";
import img031Mini from "./images/work31-mini.jpeg";
import img032Mini from "./images/work32-mini.jpeg";
import img033Mini from "./images/work33-mini.jpeg";
import img034Mini from "./images/work34-mini.jpeg";
import img035Mini from "./images/work35-mini.jpeg";
import img036Mini from "./images/work36-mini.jpeg";
import img037Mini from "./images/work37-mini.jpeg";
import img038Mini from "./images/work38-mini.jpeg";
import img039Mini from "./images/work39-mini.jpeg";
import img040Mini from "./images/work40-mini.jpeg";
import img041Mini from "./images/work41-mini.jpeg";
import img001Nano from "./images/work1-nano.jpeg";
import img002Nano from "./images/work2-nano.jpeg";
import img003Nano from "./images/work3-nano.jpeg";
import img004Nano from "./images/work4-nano.jpeg";
import img005Nano from "./images/work5-nano.jpeg";
import img006Nano from "./images/work6-nano.jpeg";
import img007Nano from "./images/work7-nano.jpeg";
import img008Nano from "./images/work8-nano.jpeg";
import img009Nano from "./images/work9-nano.jpeg";
import img010Nano from "./images/work10-nano.jpeg";
import img011Nano from "./images/work11-nano.jpeg";
import img012Nano from "./images/work12-nano.jpeg";
import img013Nano from "./images/work13-nano.jpeg";
import img014Nano from "./images/work14-nano.jpeg";
import img015Nano from "./images/work15-nano.jpeg";
import img016Nano from "./images/work16-nano.jpeg";
import img017Nano from "./images/work17-nano.jpeg";
import img018Nano from "./images/work18-nano.jpeg";
import img019Nano from "./images/work19-nano.jpeg";
import img020Nano from "./images/work20-nano.jpeg";
import img021Nano from "./images/work21-nano.jpeg";
import img022Nano from "./images/work22-nano.jpeg";
import img023Nano from "./images/work23-nano.jpeg";
import img024Nano from "./images/work24-nano.jpeg";
import img025Nano from "./images/work25-nano.jpeg";
import img026Nano from "./images/work26-nano.jpeg";
import img027Nano from "./images/work27-nano.jpeg";
import img028Nano from "./images/work28-nano.jpeg";
import img029Nano from "./images/work29-nano.jpeg";
import img030Nano from "./images/work30-nano.jpeg";
import img031Nano from "./images/work31-nano.jpeg";
import img032Nano from "./images/work32-nano.jpeg";
import img033Nano from "./images/work33-nano.jpeg";
import img034Nano from "./images/work34-nano.jpeg";
import img035Nano from "./images/work35-nano.jpeg";
import img036Nano from "./images/work36-nano.jpeg";
import img037Nano from "./images/work37-nano.jpeg";
import img038Nano from "./images/work38-nano.jpeg";
import img039Nano from "./images/work39-nano.jpeg";
import img040Nano from "./images/work40-nano.jpeg";
import img041Nano from "./images/work41-nano.jpeg";

interface UniversalImage {
    minified: string;
    normal: string;
    nano: string;
    label: string;
    description?: string;
}

const images: UniversalImage[] = [
    {minified: img001Mini, normal: img001, nano: img001Nano, label: "Hard pill „old / new“ , 30 x ⌀15, marble ( marmor )"},
    {minified: img002Mini, normal: img002, nano: img002Nano, label: "equilibrium, 60 x 90, fine art print ( Fine Art Druck )"},
    {minified: img003Mini, normal: img003, nano: img003Nano, label: "yours, 180 x 120, fine art print ( Fine Art Druck )"},
    {minified: img004Mini, normal: img004, nano: img004Nano, label: "schlaf darüber, 120 x 15, neon sign ( Neonschrift )"},
    {minified: img005Mini, normal: img005, nano: img005Nano, label: "aktiv / kohle, 3,5 x ⌀15, granit ( Granit )"},
    {minified: img006Mini, normal: img006, nano: img006Nano, label: "Odessa bar, 180 x 120, fine art print ( Fine Art Druck )"},
    {minified: img007Mini, normal: img007, nano: img007Nano, label: "Hard pill „exit / entry“, 25 x ⌀ 12, marble ( Marmor )"},
    {minified: img008Mini, normal: img008, nano: img008Nano, label: "Templum 27, 150 x 120, fine art print ( Fine Art Druck )"},
    {minified: img009Mini, normal: img009, nano: img009Nano, label: "chef d’oeuvre, 180 x 120, fine art print ( Fine Art Druck )"},
    {minified: img010Mini, normal: img010, nano: img010Nano, label: "untemperatured, 180 x 120, fine art print ( Fine Art Druck )"},
    {minified: img011Mini, normal: img011, nano: img011Nano, label: "butcher’s venus, 100 x 80, fine art print ( Fine Art Druck )"},
    {minified: img012Mini, normal: img012, nano: img012Nano, label: "kommt er ?, 90 x 60, fine art print ( Fine Art Druck )"},
    {minified: img013Mini, normal: img013, nano: img013Nano, label: "yours II, 90 x 60, fine art print ( Fine Art Druck )"},
    {minified: img014Mini, normal: img014, nano: img014Nano, label: "In Love, 100 x 85, fine art print ( Fine Art Druck )"},
    {minified: img015Mini, normal: img015, nano: img015Nano, label: "wood secret, 70 x 100, lenticular print ( Lenticular Druck )"},
    {minified: img016Mini, normal: img016, nano: img016Nano, label: "Estelle, 150 x 120, lenticular print ( Lenticular Druck )"},
    {minified: img017Mini, normal: img017, nano: img017Nano, label: "Mädchen im Laub, 120 x 80, fine art print ( Fine Art Druck )"},
    {minified: img018Mini, normal: img018, nano: img018Nano, label: "delicate work, 150 x 100, fine art print ( Fine Art Druck )"},
    {minified: img019Mini, normal: img019, nano: img019Nano, label: "Schlaf darüber II, 60 x 90, fine art print ( Fine Art Druck )"},
    {minified: img020Mini, normal: img020, nano: img020Nano, label: "here, 60 x 60, fine art print ( Fine Art Druck )"},
    {minified: img021Mini, normal: img021, nano: img021Nano, label: "bride on ice, 60 x 60, fine art print ( Fine Art Druck )"},
    {minified: img022Mini, normal: img022, nano: img022Nano, label: "ephemeral flowers, 60 x 60, fine art print ( Fine Art Druck )"},
    {minified: img023Mini, normal: img023, nano: img023Nano, label: "b3, 120 x 80, fine art print ( Fine Art Druck )"},
    {minified: img024Mini, normal: img024, nano: img024Nano, label: "hard pill „inside / out, 30 x 30 x 3, granit ( Granit )"},
    {minified: img025Mini, normal: img025, nano: img025Nano, label: "Nikolai II, 60 x 90, fine art print ( Fine Art Druck )"},
    {minified: img026Mini, normal: img026, nano: img026Nano, label: "Nikolai, 120 x 80, fine art print ( Fine Art Druck )"},
    {minified: img027Mini, normal: img027, nano: img027Nano, label: "kosher, 100 x 80, fine art print ( Fine Art Druck )"},
    {minified: img028Mini, normal: img028, nano: img028Nano, label: "bride on ice II , 60 x 60, fine art print ( Fine Art Druck )"},
    {minified: img029Mini, normal: img029, nano: img029Nano, label: "hard pill „inside / out, 30 x 30 x 3, granit ( Granit )"},
    {minified: img030Mini, normal: img030, nano: img030Nano, label: "Copper Announcement # 1", description: "„you have to learn to feel confident about a prospect of failing, because it’s inevitable“ ( „Du sollst die Perspektive des Scheiterns selbstbewusst hinnehmen, weil diese unvermeidlich ist“ ) 60 x 60, Copper, Canvas ( Kupfer, Leinwand )"},
    {minified: img031Mini, normal: img031, nano: img031Nano, label: "Copper Announcement # 3", description: "Time is not for saving, ( „ Zeit ist nicht zum sparen da“) 200 x 40 Copper, Canvas ( Kupfer, Leinwand )"},
    {minified: img032Mini, normal: img032, nano: img032Nano, label: "Copper Announcement # 5 „Nr. 1“, 30x30, Copper, Canvas ( Kupfer, Leinwand, 30x30 )"},
    {minified: img033Mini, normal: img033, nano: img033Nano, label: "Flachland und unmittelbare Einsichten , 70x50, fine art print ( Fine Art Druck )"},
    {minified: img034Mini, normal: img034, nano: img034Nano, label: "Hard pill „exit / entry“, 25 x ⌀ 12, marble ( Marmor )"},
    {minified: img035Mini, normal: img035, nano: img035Nano, label: "Hispektive , 180x120 , fine art print ( Fine Art Druck )"},
    {minified: img036Mini, normal: img036, nano: img036Nano, label: "Child with something golden, 60x60 Print on wood , Epoxy resin ,golden object ( Kind mit goldenem Etwas, Druck auf Holz, Epoxiharz, goldenes Objekt )"},
    {minified: img037Mini, normal: img037, nano: img037Nano, label: "Requiem Box I, 120 x 60, wood, print, vintage clock , Copper ( Holz, Fotografie, Vintage-Uhr , Kupfer )"},
    {minified: img038Mini, normal: img038, nano: img038Nano, label: "Requiem Box I, cover, closed ( Forderseite, geschlossen )"},
    {minified: img039Mini, normal: img039, nano: img039Nano, label: "Requiem Box II, 120 x 60, wood, print, Copper ( Holz, Fotodruck, Kupfer )"},
    {minified: img040Mini, normal: img040, nano: img040Nano, label: "O.T., 60 x 90 fine art print ( Fine Art Druck )"},
    {minified: img041Mini, normal: img041, nano: img041Nano, label: "Mommy, 70 x 70, fine art print ( Fine Art Druck )"},
];

export const WorksPage: React.FC = () => {

    return <>
        <Masonry
            breakpointCols={{
                default: 4,
                1600: 3,
                1100: 2,
                700: 1,
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
        >
            {images.map((img) => <S.Image
                key={img.normal}
                src={img.minified}
                nanoSrc={img.nano}
                onClick={() => FullscreenViewService.instance.show(img.normal, images.map(i => i.normal), `${img.label}`, img.description)}
            />)}
        </Masonry>
    </>;

};
