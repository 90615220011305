import styled from "styled-components";

export const Title = styled.h1`
  font-size: 1.2em;
  display: block;
  margin: 50px 40px;
  padding: 6px 6px;
  font-weight: 600;
  border-bottom: 3px solid black;
  width: fit-content;
`;

export const Container = styled.div`
  padding: 20px;
`;
