import React from "react";
import * as S from "./styles";
import image from "./images/home.jpg";
import imageMini from "./images/home-mini.jpeg";
import imageNano from "./images/home-nano.jpeg";

export const HomePage: React.FC = () => {

    return <S.HomeContainer>
        <S.NameLabel>Victoria Rosenman</S.NameLabel>
        <S.Image src={image} minifiedSrc={imageMini} nanoSrc={imageNano} style={{objectFit: "cover"}}/>
    </S.HomeContainer>;

};
